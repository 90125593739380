input[type="file"] {
  position: relative;
  width: 100%;
  margin-top: 2px;
  height: calc(1.5em + 1rem + 2px);
  font-size: 1rem;
  font-weight: 400;
}

input[type="file"]::file-selector-button {
  width: 120px;
  color: transparent;
}


input[type="file"]::after {
  position: absolute;
  pointer-events: none;
  top: 7px;
  left: 20px;
  color: #0964b0;
  content: "Upload File";
}

/* ------- From Step 1 ------- */

/* file upload button */
input[type="file"]::file-selector-button {
  border-radius: 4px;
  
  height: 40px;
  cursor: pointer;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  margin-right: 16px;
  transition: background-color 200ms;
}

/* file upload button hover state */
input[type="file"]::file-selector-button:hover {
  background-color: #f3f4f6;
}

/* file upload button active state */
input[type="file"]::file-selector-button:active {
  background-color: #e5e7eb;
}
