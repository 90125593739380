
:root{
    --back-color:#EAEAEA;
    --back-color-secondary:white;
    --button-color:#2F3CED;
    --input-bg:#CBCBCB;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
/* ============Loading==============*/
.outerspinner {
  position: fixed; /* Change from fixed to absolute */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  visibility: hidden;
}
.outerspinner.active {
  visibility: visible;
}
.spinner {
   width: 56px;
   height: 56px;
   display: grid;
   border-radius: 50%;
   -webkit-mask: radial-gradient(farthest-side,#0000 40%,#000000 41%);
   background: linear-gradient(0deg ,rgba(0,0,0,0.5) 50%,rgba(0,0,0,1) 0) center/4.5px 100%,
        linear-gradient(90deg,rgba(0,0,0,0.25) 50%,rgba(0,0,0,0.75) 0) center/100% 4.5px;
   background-repeat: no-repeat;
   animation: spinner-d3o0rx 1.2s infinite steps(12);
}

.spinner::before,
.spinner::after {
   content: "";
   grid-area: 1/1;
   border-radius: 50%;
   background: inherit;
   opacity: 0.915;
   transform: rotate(30deg);
}

.spinner::after {
   opacity: 0.83;
   transform: rotate(60deg);
}

@keyframes spinner-d3o0rx {
   100% {
      transform: rotate(1turn);
   }
}

/* ============Loading==============*/
#pswinp{
  padding: 7px;
  border:none;
}
.navigateheaders{
  width: 100%;
  height: 20px;
  display: grid;
  grid-template-columns: 1fr 40fr 1fr;
}
.logoText{
  font-family: Rockwell;
font-weight: bold;
font-size: 48px;
}
.background{
    background-color: var(--back-color);
    padding: 40px;
    height: 100vh;
}
.contentbackgroundforfull{
  background-color:#EAEAEA;
  padding: 20px;
 
  height: auto;
  width: 100%;
 

}
.contentinnerforfull{
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  width: 100%;
  overflow: auto;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.contentbackground{
  background-color:#EAEAEA;
  padding: 30px;
  min-height: 90vh;
  height: auto;
  width: 100%;

}
.contentinner{
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  height: auto;
  min-height: 80vh;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
.innerFrameforset{
  padding: 5px;
  height:auto;
  display : flex;
  flex-direction: column;
}
.innerFrame{
    padding: 5px;
    height:100%;
    display : flex;
    flex-direction: column;
    justify-content: space-between;
   }
.mainform2{
  display: grid;
  padding: 10px;
  grid-template-columns: 2fr 2fr;
  gap:10px;
  max-height: 290px;
  overflow: auto;
}

.mainform{
    display: grid;
    height: 100%;
    grid-template-columns: 1fr 2fr;
    gap:10px;
}

.checkboxes input[type=checkbox] {
  width: 20px;
  height: 20px;
  accent-color: black;
}
.checkboxes label{
  /* font-size: larger; */
  padding-left: 10px;
}
input[type=search] {
outline: none;
padding: 2px 5px;
border: 1px solid rgb(75, 74, 74);
border-radius: 5px;
}
#search{
  border: none;
}
.formgroup{
    display: grid;
    /* grid-template-rows: repeat(5,1fr); */
    gap: 20px;
    max-height: 500px;
    overflow:auto;
    padding-right: 20px;
 
}
.notificationElement::-webkit-scrollbar,
.mainform2::-webkit-scrollbar,
.formgroup::-webkit-scrollbar {
  width: 3px; /* Width of the scrollbar */

}
.notificationElement::-webkit-scrollbar-track,
.mainform2::-webkit-scrollbar-track,
.formgroup::-webkit-scrollbar-track {
  background-color: #f4f4f4; /* Track color */
}
.notificationElement::-webkit-scrollbar-thumb,
.mainform2::-webkit-scrollbar-thumb ,
.formgroup::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Scrollbar thumb color */
  border-radius: 2px; /* Border radius for the scrollbar thumb */
}
.notificationElement::-webkit-scrollbar-thumb:hover,
.mainform2::-webkit-scrollbar-thumb:hover,
.formgroup::-webkit-scrollbar-thumb:hover {
  background-color: #999; /* Hover color for the scrollbar thumb */
}
.twosplit{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
margin-bottom: 60px;
margin-top: 60px;
}
.inputgrp2{
  display: grid;
  display: grid;
  
  display: grid; 
  
  grid-template-columns: 6fr 1fr 4fr;
}
.inputgrp2 input{
  border: 1px solid black;
  width: 50%;
  height: 40px;
  border-radius: 5px;
  padding: 15px;
 
}

.inputgrp{
display: grid;
grid-template-columns: 6fr 1fr 12fr;
}

.inputgrp select{
  
  border: 1px solid black;
  
  border-radius: 5px;
}
.inputlike {
  border: 1px solid black;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
}
.atgrid input,
.form-group input,
.inputgrp input{
    border: 1px solid black;
    width: 100%;
    border-radius: 5px;
  
   
}
.inputpsw{
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
}
.inputgrp2 .disabledbox,
.inputgrp .disabledbox{
  border:0px;
}
.form-group textarea,
.inputgrp2 textarea,
.inputgrp textarea{
  width: 100%;
  border: 1px solid black;
}
.inputgrp2 span,
.inputgrp span{
    font-weight: bolder;
    font-size: 1.5rem;
}
.inputgrp2 label,
.inputgrp label{
    font-size:1.2rem;
    margin-bottom: 0px;
   display: flex;
   align-items: start
}
.disabledbox{
  background-color: var(--input-bg);
  height: calc(1.5em + 0.5rem + 2px);
  width: 100%;
  border:0px !important;
  outline: none;
  border-radius: 5px;
  padding: 15px;
}
.btngrp{
    display: flex;
    justify-content: end;
    margin-right: 30px;
    padding-bottom: 10px;
    padding: 10px;
}
.profile-picture {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .image-group {
   width: 300px; /* Adjust the width and height as needed */
    height: 300px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 10px; /* Adjust the margin as needed */
  }
  #google-signin-button{
    width: 30px;
    height: 30px;
  }
 #google-signin-button img,
  .image-group img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the entire container */
  }
  .file-upload-btn {
   
    display: inline-block;
    background-color: var(--button-color); 
    color: white;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .file-upload {
    position: absolute;
    left: -9999px;
  }
  
  /* Style to hide the file input filename */
  .file-upload::-webkit-file-upload-button {
    visibility: hidden;
  }
  
  .file-upload::before {
    content: 'Choose File';
    display: inline-block;
    background-color: var(--button-color); 
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  #wrappercenter{
    width: 100vw;
    height: auto;
    display: flex;

  }
  /* Optional: Style the file input label when a file is selected */
  .file-upload:valid + .file-upload-btn::before {
    content: 'Change File';
  }
  
/* certificate template */
.certificate {
    width: 700px;
    height: 400px;
}
.certibtn{
  width: 700px;
  margin-top: 10px;
  display:grid;
  grid-template-columns:  1fr 1fr;
  justify-items: center;
  gap:30px;
  
}



.PhoneInput{
  border:1px solid black;
}
#phone{
  padding: 0px;
  margin: 0px;
  min-height:20px;
  height: 100%;
  border: none;
  outline: none;
}
.disabledbox .PhoneInputInput{
background-color: var(--input-bg)!important;
}
#printbtn{
  width: 200px;
}
.wrapper{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner {
    width: 100%;
    height: 100%;
    border : 10px solid #B2B5E1;
    display: grid;
    grid-template-rows: max-content;
    padding: 20px; 
    text-align: center;  
    background-color: #ffffff; 
    background-repeat: no-repeat;
    font-size: 1.5rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
}
.centerimg{
  display: flex;
  justify-content: center;
}
.inner p{
  word-wrap:break-word;
  font-size: smaller;
  margin-bottom: 0px;
}
.login-container {  
  flex-direction: row; 
  height: auto;
  width: auto;

}



.image-section{
  flex:1;
  width: 100%;
  height: 700px;
}
.form-section{
  flex:1;
  padding: 15px;
  margin:10px;
}
#wrappernav{
  display: flex;
  flex-direction: column;
}

#must{
  display: block !important;
}
/* Media Queries */


/* Mobile Mode (adjust breakpoints as needed) */
@media screen and (max-width: 480px) {
   /* Styles for larger screens (laptop and up) */

    h1 {
    font-size: 2rem; 
  }
  
  h2 {
    font-size: 1.75rem; 
  }
  
  h3, h4 {
    font-size: 1.5rem; 
  }
  
  h5, h6 {
    font-size: 1.25rem; 
  } 
   .login-container {
    height: auto;
    flex-direction: column;  
  
  }
  .image-group {
   width: 200px;
  height: 200px;
  }
/* style for outlet*/
#wrappercenter {
  display: block;
  position: relative; /* Enable absolute positioning for children */
  width: 100vw;
  height: 100%;
}
.certificate{
  width: 300px;
  height: 300px;
}
.certibtn{
  width: 300px;
 gap: 0px;
  grid-template-columns: 1fr;
}
.inner{
  margin-top: 10px;
}
.inner h3{
  font-size: 1.2rem;
}
.inner h4{
  font-size: 1.1rem;
}
.inner p{
  font-size: 0.9rem;
}

#sidebar {
  width: 10vw;
  position: absolute; /* Make it positioned within the wrapper */
  top: 0;
  left: 0; /* Place it at the top-left corner */
  height: 100%; /* Or desired sidebar height */
  background-color: #ffffff; /* Optional background color */
  z-index: 99;
  box-shadow: 4px 0px 3px rgba(0, 0, 0, 0.2);
  overflow: auto;
}

#outlet {
  flex: 1;
  overflow: hidden; 
} 
/*style for outlet*/
 .maxheight{
  height: 500px !important;
 }
  .image-section,
  .form-section { 
    width: 100vw; 
    flex: 1;  
    padding: 2rem; 
    margin: 0px;
  }

  .image-section{
    display: none;
  }
  .form-section{
    padding-top: 5px;
  }
  .contentbackgroundforfull{
    width: 100%;
    padding: 10px;
    height: auto;
    
  }
  .contentinnerforfull{
    height: auto;
    
  }
  .contentbackground{
    width: 100%;
    padding: 10px;
    height: auto;
  }
 
 .contentinner{
 overflow: auto;
 min-height: 87vh;
 }
 .innerFrame{
  grid-template-rows: 1fr 15fr 1fr;
 }
  .mainform {
    grid-template-columns: 1fr;  
    display: flex;
    flex-direction: column;
    max-height: fit-content
  }
  .mainform2{
    grid-template-columns: 1fr ;
    gap:10px;
    max-height: 290px;
    overflow: auto;
  }
 
  .formgroup {
     
   gap: 20px;
    max-height: none;
    

  }
  .profile-picture {   
    height: auto;
  }
  .twosplit{
    margin-top: 10px;
    grid-template-columns: 1fr;
    gap:10px;
  }
  .inputgrp2{
   grid-template-columns: 6fr 1fr 6fr;
  }
.inputgrp2,
.inputgrp{
 margin-top: auto;
 margin-left: 2px;
}
.form-group textarea,
.inputgrp2 textarea,
.inputgrp textarea{
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.atgrid input,
.form-group input,
.disabledbox,
.inputgrp2 input,
.inputgrp  select,
.inputgrp input,
.PhoneInput{
height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.inputpsw{ 
  height: calc(1.5em + 0.5rem + 2px);
  display: flex;
  padding: 0 rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
#pswinp{
  padding: 2px;
  height: calc(1.2em + 0.25rem + 2px);
}
.inputgrp2 label{
  font-size: 1rem;
}
.inputgrp2 input{
  width: 100%;
}
#must{
  font-size: 0.9rem;
}
.absoluteleft{
  position: absolute !important;
  max-width: 250px;
  left:100px;
}
  .inputgrp{
    display: block;
  }

 .inputgrp label{
  display: none;
 }
 
  .inputgrp span {
    display: none;
  }
}