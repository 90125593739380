.doclink{
  color: #2F3CED;
  cursor: pointer;
  text-indent: 100px;
}
.doclink2{
  color: #2F3CED;
  cursor: pointer;
  display: inline;
}
.doclink2 i
.doclink i{
  text-indent: 10px;
}
.doclist{
  height:fit-content ;
}
.ActiveLink{
    background-color:#2F3CED;
    font-size: 1.2rem;
    padding-bottom: 5px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.SubActiveLink{
  background-color:#F42020;
  font-size: 1.2rem;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    
}
.logodesign{
  height: 4.375rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 800;
  text-align: center;
  letter-spacing: 0.05rem;
  z-index: 1;
}
.logoicon{
  display:flex ;
  color: black;
}
.logoicon img{
  width: 85px;
  height: 70px;
}
.Activeicon{
    color:#f8f9fc;
}
.navgrid{
  height: 80px;
    background-color: #f8f9fc;

  }
  .gridnav{
    display: grid;
    grid-template-columns:1fr 1fr  6fr  1fr 1fr;
    width: 100%;
    gap: 10px;
    margin-bottom: 10px;
    justify-items: end;
    align-items:flex-start;
  }
  .gridnav2{
    display: grid;
    grid-template-columns:1fr  6fr  2fr ;
    width: 100%;
    gap: 10px;
    margin-bottom: 10px;
    justify-items: end;
    align-items:flex-start;
  }
  
  
 
  .profile{
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
  .username{
    color:black;
  }
  .notification-container {
    margin-top: 10px;
    position: relative; /* Make the container positionable */
    display: inline-block; /* Allow inline elements inside */
  }

  .notification-count {
    width: 25px;
    height: 25px;
    position: absolute; /* Position the count badge on top of the bell */
    top: 0; /* Align the top edge with the bell's top */
    right: 3px; /* Position slightly to the right for spacing */
    padding: 5px 5px; 
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%; /* Make the badge circular */
    background-color: red; /* Set the background color to red */
    color: white; /* Set the text color to white */
    font-size: 1rem; /* Adjust font size as needed */
    font-weight: bold; /* Make the number stand out */
  }
  #notipanel{
    position: relative;
    z-index: 100 !important;
    
  }
  .notificationElement{
    display: grid;
    margin-bottom: 5px;
    grid-template-columns: 1fr 4fr ;
    padding: 3px;
    border: 2px solid rgb(196, 196, 230);
    border-radius: 5px;
    overflow: auto;
  }
  .notificationElement img{
    width: 100px;
    height: 120px;
  }
  .notificationcontent{
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    overflow: auto;
  }
  .scrollclass{
    height: 90%;
    width: 100%;
    overflow-y: auto;
  }
  .headcontrols{
    display: grid;
    grid-template-columns: 3fr 3fr 1fr;
    padding: 5px;
    margin-bottom: 10px;
  }
  .alignright{
    text-align: right;
  }
  .notificationPanel{
    width: 450px;
    padding: 10px;
    border-radius: 10px;
    background-color: white;
    height: 85vh;
  }
  .borderimg{
   border: 2px solid rgb(255, 255, 255);
   margin-right: 20px;
   box-shadow: 5px 5px 6px 0px #d6d6d6;
   
  }
 #profi{
  width: 50px;
  height: 50px;
 }
 #noti{
  width: 40px;
  height: 40px;
 }
  .navcolor{
    opacity: 1;
    border: none;
  }
 
  .searchbar{
     width: 50%;
    display: grid;
    grid-template-columns: 2fr 20fr 2fr;
    background-color: white;
    padding: 10px;
    margin-top:auto;
    margin-bottom: auto;
    min-width: 150px;
    max-width: 500px;
    border: 2px solid rgb(255, 255, 255);
    box-shadow: 5px 5px 6px 0px #d6d6d6;
    border-radius: 30px;
   overflow: hidden;
  }
  .user-list{
    margin-top: 10px;
   border: 1px solid black;
   border-radius: 5px;
   padding: 5px;
   height: auto;
   max-height: 100px;
   overflow-y: scroll;
  }
  .listemail{
    display: flex;
    margin-bottom: 2px;
  }
  #customeinpu{
    border:0px;
    padding: 0px;
    outline: none;
  }
  
  .selectedemail{
    padding: 0px 10px;
    width: fit-content;
    background-color: #eae7e7;
    border-radius: 20px;
  }
  .usersingle{
    cursor: pointer;
    margin: 5px;
  }
  .usersingle :hover{
    background-color: #eae7e7;
  }

  .usersingle label{
    font-size: 0.9rem;
  }
  .searchinput{
    overflow: hidden;
    border: none;
    outline: none;
    color: black;
  }
  .headingbtn{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footermain{ 
    height: 15vh;
    width: 100%;
  }
  .part1{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
    background-color: rgb(255, 255, 255);
    
  }
  .boderleftright{
    border-left: 2px solid black;
    border-right: 2px solid black;
    padding-left: 90px;
    padding-right: 90px;
  }
  .part2{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
    background-color: #B3B6E1;
  }
  .divider{
    padding:10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* grid-template-rows: 1fr 6fr 1fr; */
  }
  .innerdivider{
    display: grid;
    grid-template-columns: 6fr 4fr;
  }
  .textinputs{
   
    display: flex;
    flex-direction: column;
  }
  .dropzone{
    max-height: 300px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    overflow: auto;
    text-align: center;
    align-items: center;
  }
  .dropzone p{
    margin-bottom: 5px;
  }
  .videoinputs{
    padding: 20px;
    height: min-content;
    display: grid;
    grid-template-rows: 1fr 1fr 4fr;
  }
 
  
  .grp{
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 10px;
    padding: 10px;
  }
  .thumb label,
  .videoinputs label,
  .grp label{
    font-size: larger;
  }
  .grp input{
    flex: 1;
    height: 40px;
   
  }
  .urlinput{
    margin-top: 20px;
    height: 40px;
  }
  .thumb{
    display: grid;
    grid-template-columns: 1fr 1fr 0fr 1fr;
    gap:10px;
    padding: 20px;
  }
  .urlinput,
  .grp input,
  .grp textarea{
    border: 1px solid black;
    width: 100%;
    outline: none;
    border-radius: 5px;
    padding: 15px;
   
  }
.cornerbtn{
  margin-top: 5px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}  
.twodiv{
  display: grid;
  grid-template-rows: 1fr 6fr;
}
.listback{
  background-color: #F2E1F5;
  border-radius: 10px;
  padding: 10px;
  height: 100%;
 
}
.listbackinner{
  margin-top: 10px;
  background-color: #CBCBCB;
  padding: 10px;
  border-radius: 5px;
  font-size: larger;
  display: grid;
  grid-template-columns: 1fr 20fr 1fr 1fr;
}
.scrolldiv{
  height: 500px;
  overflow-x: auto;
  padding-right: 10px;
}
.scrollclass::-webkit-scrollbar,
.scrolldiv::-webkit-scrollbar {
  width: 3px; /* Width of the scrollbar */

}
.scrollclass::-webkit-scrollbar-track,
.scrolldiv::-webkit-scrollbar-track {
  background-color:  #f4f4f4; /* Track color */
}
.scrollclass::-webkit-scrollbar-thumb,
.scrolldiv::-webkit-scrollbar-thumb {
  background-color: #ccc; 
  border-radius: 2px;
}
.scrollclass::-webkit-scrollbar-thumb:hover,
.scrolldiv::-webkit-scrollbar-thumb:hover {
  background-color:#999 /* Hover color for the scrollbar thumb */
}
.displayblock{
  display: block;
}
.barhide{
  display:none;
}
.logodisphide{
  display: block;
}
.selectstyle{
  height: 40px;
  margin: auto;
}
.tabinp{
  margin-top: 10px;
  min-width: 100px;
}
.datetimegrp{
  width: 100%;
  display:flex;
  gap: 10px;
}
.zoomset{
  width: 50%;
  display: grid;
  grid-template-columns: 5fr 1fr 2fr 1fr 2fr;
}
.zoomopt{
  width: 100%;
  display: grid;
  gap:10px;
  grid-template-columns:1fr 20fr;
}

.zoomset p{
  align-items: left;
}
.calenderheadergrp{
display: grid;
grid-template-columns: 5fr 1fr 1fr;
gap: 20px;
margin-bottom: 10px;
}

@media screen and (max-width: 480px) {
  .searchbar{
    gap: 5px;
    height: 40px;
    width: 80px;
  }
  .notification-container{
   margin-top: 3px;
  }
  #profi{
    width:40px;
    height: 40px;
  }
  #noti{
    width: 35px;
    height: 35px;
  }
  .gridnav2{
    grid-template-columns:1fr 12fr  12fr ;
  }
  .navgrid{
    height: 60px;
    align-items: center;
    justify-content: center;
  }
  .listbackinner{
    grid-template-columns: 1fr 10fr 1fr 1fr ;
    gap:5px;
  }
  .notification-count{
    font-size: 0.6rem;
    width: 15px;
    height: 15px;
    right:-10px;
  }
  .borderimg{
    margin-right: 0px;
  }
  .selectstyle{
    justify-self: flex-start;
    width: 100px;
    height:35px;
    margin:5px;
  }
  .tabinp{
    margin-top: 5px;
  }
 .notificationElement{
  width: auto;
  max-height:150px;
 }
 .notificationElement img{
  width: 100px;
  height: 100px;
 }
  .notificationPanel{
    width: 300px;
    /* width: auto; */
  }

  .ActiveLink{
    font-size: 0.65rem;
  }
  .SubActiveLink{
    font-size: 0.65rem;
  }
  .displayblock{
    display: none;
  }
  .hide{
    display: none;
  }
  .barhide{
    display: block;
    margin:auto;
  }
  .logodisphide{
    display: none;
  }
  .boderleftright{
    padding: 5px;
  }
  .part2{
    grid-template-columns: 1fr ;
  }
  .profile{
    gap:"0px";
    margin-top: 3px;
  }
  #spanid,
  #profilename{
    display: none;
  }
  .innerdivider{
    grid-template-columns: 1fr;
  }
  .thumb{
    grid-template-columns: 1fr 1fr 0fr;
  }
  .thumb label {
display: none;
  }
  .grp label{
    display: none;
  }
  .grp{
    grid-template-columns: 1fr;
  }
}

