:root {
  --blue: #508bfc;
  --black: black;
  --white: #fff;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}
.supercontainernew{
  display: grid;
  grid-template-rows: 1fr 40fr;
  max-height: 75vh;
}
.createbtn{
  background-color: rgb(255, 254, 254);
  width: 100%;
  display: flex;
  justify-content: end;
  padding-right: 30px;
  border-top-right-radius:  5px;
  border-top-left-radius: 5px;
}
.containersnew {
  flex: 1;
  height: 350px;
  width: 250px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 4fr 1fr;
  gap: 10px;
  padding: 5px;
  transition: transform 0.2s ease;
  background-color: var(--white);
  color: var(--black);
}
.containersnew:hover {
  transform: scale(1.1);
  /* transform: translateY(-15px); */
}
.imagedivnew img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.imagedivnew {
  border-radius: 10px;
  height: 150px;
  overflow: hidden;
}

.createcourse {
  flex: 1;
  padding-bottom: 3px;
  width: 100%;
}

.maincontainernew{
  padding:20px;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  height: 75vh;
  overflow-y: scroll;
}

.maincontainernew::-webkit-scrollbar {
  width: 2px;
}


.maincontainernew::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 5px;
}

.maincontainernew::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 4px;
}

.maincontainernew::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.contentnew {
  padding: 10px;
}
.editicons {
  width: 100%;
  display: grid;
  gap: 10px;
  grid-template-columns: 8fr 1fr 1fr 1fr;
}
.bar {
  display: grid;
  grid-template-columns: 7fr 1fr;
  width: 70%;
  flex: 7;
}

.formControl {
  display: block;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  /* padding: 0.375rem 0.75rem; */
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #d1d3e2;
  border-bottom-left-radius: 0.35rem;
  border-top-left-radius: 0.3rem;
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.pad10 {
  padding-left: 10px;
}


 @media (max-width:480px) {
  .containersnew{
    width: 185px;
    height: 275px;
    gap:2px;
    grid-template-rows: 100px auto;
  }
  .createbtn{
    padding-right: 0px;
    border-top-right-radius:  0px;
    border-top-left-radius: 0px;
  }

 .contentnew p{
  margin-bottom: 2px;
  min-height: 65px;
 }
 
  .imagedivnew{
   height: 100px;
   overflow:hidden;
  }
  .maincontainernew {
    width: 100%;
    justify-items: center;
    grid-template-columns: 1fr 1fr;
    gap:5px;
    padding: 5px;

  }
  .supercontainernew{
    max-height: 80vh;
  }
  .editicons{
    gap:5px;
   
  }
  .editicons h4 {
    font-size: 1rem;
  }
  .editicons h5{
    font-size: 0.9rem;
  }
  
 }

 @media (max-width:448px) {
  .maincontainernew{
    grid-template-columns: 1fr;
    gap: 10px;
  }
 }