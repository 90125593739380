.testscontainer{
    width: 100%;
    height: 100%; 
    background-color: rgb(197, 196, 196);
    border-radius: 10px;
    padding:30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* overflow-y: scroll;  */
}
.testcontainermain{
    height: 88vh;
    width: 100%;
   background-color: white;
  
    padding: 10px;
    padding-left: 30px;
    padding-top: 20px;
}




.table thead {
  position: sticky;
  top: 0;
  background-color: #ffffff;

}

.table tbody {
  overflow-y: auto; /* Enable vertical scrolling for the body */
}



.singletest{
    flex: 1;
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    padding-bottom: 2px;
    display: grid;
    align-items: center;
    grid-template-columns:  3fr 3fr 3fr 3fr ;
    gap: 10px;
}
/* .Questionstable{
    margin-top: 10px;
 
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 10px;
    width: 100%;
   
}
.Qthead{
    background-color: black;
    color: white;
    border-radius: 10px;
    text-align: left;
    padding-left: 20px;
  
}
.Qthead th{
    padding: 5px;
   
}
.bodyelement{
    text-align: left;
    padding: 5px;
    padding-left: 10px;

} */
 .listgroup::-webkit-scrollbar,
.instruction::-webkit-scrollbar,
.testscontainer::-webkit-scrollbar {
    width: 5px;
     padding: 2px;
  }
  .listgroup::-webkit-scrollbar-track,
  .instruction::-webkit-scrollbar-track,
  .testscontainer::-webkit-scrollbar-track {
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
  }
  .listgroup::-webkit-scrollbar-thumb,
  .instruction::-webkit-scrollbar-thumb,
  .testscontainer::-webkit-scrollbar-thumb {
    background-color: #a7a5a5; /* Color of the scrollbar thumb */
    border-radius: 4px;
  }
  .listgroup::-webkit-scrollbar-thumb:hover,
  .instruction::-webkit-scrollbar-thumb:hover,
  .testscontainer::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  .atdiv{
    width: 100%;
   height: 500px;
    padding: 10px;
    display: grid;
    grid-template-rows: 5fr 1fr;
  }
  .div3{
    padding: 10px;
    display: grid;
    grid-template-rows: 1fr 8fr 1fr;
  }
  .instruction{
    padding-left: 30px;
    text-align:left;
    height: 430px;
    overflow-y: auto;
    font-size: large;
  }
  .instruction li{
    margin-left: 100px;
  }
  /* .atbg{
    
    background-color: #EAEAEA;
    width: 100vw;
    height: 88vh;
   
  } */
  .atgrid{
    padding: 10px;
    height: 65vh;
    display: grid;
    grid-template-rows: 1fr 5fr;
 
  }
 /* Add this CSS to your stylesheet or style tag */
.lielement {
    margin-bottom: 20px; 
   
}
.choice{
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 20fr;
}
.smalltextbox{
height: 30px;
}
.edititems{
  cursor: pointer;
  display: grid;
  grid-template-columns: 5fr 8fr 1fr;
}
.atgrid input{
  border: 1px solid black;
  border-radius: 5px;
}
.lielement input[type="radio"] {
    margin-right: 5px; /* Add margin-right to create space between the radio button and label */
}

.lielement label {
    vertical-align: middle; /* Align the label vertically with the radio button */
}
.listgroup{ 
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr ;
    overflow: auto;
    padding-right: 5px;
}
.atbtndiv{
    display: grid;
    grid-template-columns: 2fr 40fr 2fr;
    gap:30px;
}

/* Default color for unchecked radio buttons */
input[type="radio"] {
    border: 2px solid #464545; /* border color */
    border-radius: 50%; /* makes the border round */
    width: 20px;
    height: 20px;
    outline: none; 

  }
  
  /* Color for checked radio buttons */
  input[type="checkbox"]{
    width: 20px;
    height: 20px;
  }
  input[type="checkbox"]:checked{
        accent-color: black;
  }
  input[type="radio"]:checked {

    accent-color: black;
  }
  
  .notfound{
    margin-left: 350px;
    border-radius: 10px;
    margin-top: 200px;
    background-color: white;
    font-weight: bolder;
    text-align: center;
    width: 50%;
    padding: 30px;
  }

/* Media Queries */


/* Mobile Mode (adjust breakpoints as needed) */
@media screen and (max-width: 480px) {
  /* Styles for larger screens (laptop and up) */
  .choice{
    margin-top: 10px;
    gap: 5px;
  }
  .singletest{
    grid-template-columns: 3fr 3fr;
    gap: 5px;
  }
  .instruction li{
    margin-left: 40px;
  }
  .atdiv{
    padding: 10px; 
  }
  .display-1{
    font-size: 3rem;
  }
}