/* Reset all margins, padding, and borders */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input::placeholder {
  font-size: 1.125rem; /* e.g., 14px, 1rem */
}
.slider_div{
  padding: 5px;
  width: 100%;
  width: 1000px;
  max-height: 600px;
  overflow: auto;
}
.slider_div img{
  margin: auto;
}
.outermini{
  width: 150px;
  height: 600px;
  align-content: center;
  overflow:auto ;
  padding: 5px;
}
.activemini{
  border:4px solid black
}
.pageshow{
  background-color: rgba(190, 113, 141, 0.37);
  width: auto;
  margin: auto;
  padding: 5px 45px;
}
.currentslide{
 outline: none;
 border: none;
 width: auto;
 max-width: 60px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding:2px;
}
.zoombtn{
  cursor: pointer;
  padding: 6px;
  
}
.zoombtn:hover{
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
}
.pageshowchild{
  padding-right: 20px;
border-right: 1px solid white
}
.headdocu{
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 5px;
  color: white;
  border-bottom: 2px solid white;
}
.wrapppt{
  margin: 5px;
  padding: 5px;
  border-radius: 10px;
  background-color: #6b6b6b;
}
.miniandoriginal{
  width: 100%;
  display:flex ;
}

.miniimg p{
  text-align: center;
  color: white;
}
.heightmini{
  height: 125px;
}
.activemini img,
.miniimg img{
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  
  height: 100%;
  width: 100px;
  object-fit: cover;
}
.assignlist{
  background-color: #F2E1F5;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.mybtn{
  min-width:100px;
  max-width: 150px;
  height: 40px;
}
.tableheader{
  display: grid;
  grid-template-columns: 25fr 10fr;
  margin-bottom: 0.9rem;
}
.tableheader2{
  display: grid;
  grid-template-columns: 25fr 3fr;
  margin-bottom: 0.9rem;
}
.tableheader3{
  display: grid;
  grid-template-columns: 10fr 6fr;
  margin-bottom: 0.9rem;
}
.inputlike,
.ql-editor{
font-size: 1rem;
}
.ql-editor img {
  max-width: 100px;  /* Limit the image width to the container size */
  height: 100px;     /* Maintain aspect ratio */
}
.centerflex{
display: flex;
justify-content: center;
align-items: center;
height: 70vh;

}
.enroll{
  background-color: #EAEAEA;
  width: 400px;
  height: 200px;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}

.lesson{
    padding: 30px;
    padding-bottom: 0px;
}
.lesson p{
  text-align: left;
  text-indent: 200px; 
  font-size: larger;
  line-height: 1.5; 
}
.viewcontent{
  width: 100%;
  height: 80vh;
  background-color: white;
  border-radius: 5px;
  overflow-y: scroll;
}

.main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  height: 500px;
  padding: 40px;
  position: relative;
  overflow: auto;
}

.VideoFrame,
.list {
  height: 400px;
}


.list {
  padding: 10px;
  background-color: #6093F0;
  border-radius: 10px;
}

.content {
  width: 100%;
  height: 90%;
  padding: 10px;
  align-items: center;
  overflow: auto; /* Use auto to add scrollbar only when necessary */
}
.viewcontent::-webkit-scrollbar{
  width: 3px;
}
.content::-webkit-scrollbar {
  width: 5px;
}
.viewcontent::-webkit-scrollbar-track,
.content::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 5px;
}
.viewcontent::-webkit-scrollbar-thumb,
.content::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 4px;
}
.viewcontent::-webkit-scrollbar:hover,
.content::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.notes{
    height: 100px;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    color: black;
    text-align: start;
    padding: 5px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 2fr 8fr;
    gap: 5px;
    font-weight: bold;
    cursor: pointer;
}

.child {
  height: 98%;
  border-radius: 5px;
  overflow: hidden;
}

.notecontent{
    padding: 60px;
}
.notecontent h1,.notecontent p{
    text-align: left;
}
.notecontent p{
    font-size: larger;
    text-indent: 200px;
    line-height: 1.5; 
}
.lessmain{
  height:750px;
  position: relative;
  background-color: #EAEAEA;
  padding: 25px;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap:20px;

}

.lesmaindiv{
  flex:1;
  color: black;
  height: 100%;
  margin-left: 10px;
  width: 90%;
  padding: 20px;
  border-radius: 15px;
  background-color: rgba(178, 181, 225, 1);
  text-align: center;
}

.coursedesc{
  text-align: left;
  margin-bottom: 15px;
  text-indent: 200px;
}
.lesdiv{
  display: grid;
  grid-template-columns: 25fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  text-align: left;
 padding-left: 10px;
 padding-right: 10px;
  color: black;
  background-color: white;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 15px;
  width: 98%;
}
.lesname{
  padding: 15px;
  font-size: larger;
  display: block;
}
.lesname:hover{
  font-weight: bold;
}
.notesdiv{
  background-color: rgb(247, 209, 235);
  border-radius: 5px;
  text-align: left;
  width: 98%;
}

.horizontal-line {
  border-bottom: 1px solid #807d7d; /* Change color and thickness as needed */
 padding-left: 10px;
 margin-right: 10px;

}
.notetitle{
  padding: 15px;
  display: grid;
  grid-template-columns: 15fr  1fr 1fr 1fr;
}
.navigationButtons{
  width: 100%;
  display: grid;
  grid-template-columns: 40fr 1fr 1fr;
 gap: 20px;
 padding: 20px;
 padding-left: 20px;
   
}
.notfoundlesson{
  width: 100%;
  height: 80%;
  background-color: #EAEAEA;
}
.notfoundinner{
  margin-left: 350px;
  border-radius: 10px;
  margin-top: 200px;
  background-color: white;
  font-weight: bolder;
  text-align: center;
  vertical-align:middle;
  width: 50%;
  padding: 30px;
}
.bttoncust{
  border: none;
  background-color: transparent;
  color:blue;
  cursor: pointer;
}
.lessonlistscroll{
  height: 350px;
  overflow-y: auto;
}
.lessonlistscroll::-webkit-scrollbar {
  width: 10px;
   padding: 2px;
}

.lessonlistscroll::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
}

.lessonlistscroll::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 4px;
}

.lessonlistscroll::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.canvas-style{
  border: 1px solid black;
  height: 100vh;
  width: 100vw;
  overflow: scroll;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media screen and (max-width: 480px) {


.outermini{
  display: none;
}
textarea::placeholder,
input::placeholder {
  font-size: 0.9rem; /* e.g., 14px, 1rem */
}
.assignlist{
  grid-template-columns: 1fr 1fr;
}
.mybtn{
  height: 35px;
  margin-top: 4px;
  margin-bottom: 2px;
}
.tableheader2,
.tableheader3,
.tableheader{
grid-template-columns: 1fr;
}
.pageshow{
  padding: 5px;
}
.slider_div img{
  width: 100%;
  height: 90vw;
  object-fit: contain;
  }

.main{
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 2fr;
  padding: 10px;
}
.enroll{
  width: 300px;
}

}


