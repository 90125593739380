.boxtype{
  width: 20px;
  height: 20px;
  border: 1px solid rgb(99, 93, 93);
  background-color: #a39c9c;
}
.anchorlike{
  border:none;
  outline: none;
  background-color: transparent;
  color: #264bc7;
}
.anchorlike:hover{
  text-decoration: underline;
}
.bg {
  width: 100%;
  background-color: #EAEAEA;
  height:88vh;
  padding-top: 10px;
}

.outer {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: grid; 
  grid-template-columns: 1fr 1fr;
  gap:20px;
}
.outer1 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 48fr 1fr 48fr;
}
.marquee-container {
  width: 100%;
  overflow: hidden;
}

.marquee-content {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 10s linear infinite;
  font-size: larger;
  font-weight: 700;
}

.marquee-container:hover .marquee-content {
  animation-play-state: paused;
}

@keyframes marquee {
  0% { transform: translateX(200%); }
  100% { transform: translateX(-100%); }
}

.labl{
  width: 13rem;
}
.lab{
  width: 17rem;
}
.selectandadd{
  display:grid;
  grid-template-columns: 1fr 1fr;

}
.twosearch{
  display: grid;
  grid-template-columns: 20fr 20fr 1fr;
  gap: 5px;
}
.dash{
  display: flex;
}
.hidebtn{
  border: none;
  outline: none;
  color: #264bc7;
  background-color: transparent;
}
.hidebtn2{
  border: none;
  outline: none;
  color: white;
  background-color: transparent;
}
.table-container {
  overflow-y:auto;
  overflow-x:auto;
  height: 500px; 
  width: 77vw;
  padding-right: 10px;
}
.table-container::-webkit-scrollbar {
  width: 3px; /* Width of the scrollbar */
}

.table-container::-webkit-scrollbar-track {
  background-color:  #f4f4f4; /* Track color */
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Scrollbar thumb color */
  border-radius: 2px; /* Border radius for the scrollbar thumb */
}

.table-container::-webkit-scrollbar-thumb:hover {
  background-color:#999 /* Hover color for the scrollbar thumb */
}
/* .first,
.First1 {
  padding: 30px;
} */
/* .second,
.second1 {
  padding: 30px;
} */

.First1,
.second1 {
  overflow: auto; /* Use "auto" to display scrollbars only when necessary */
}
.First1::-webkit-scrollbar,
.second1::-webkit-scrollbar {
  width: 4px;
  height: fit-content;
}

/* Webkit browsers (Chrome, Safari) */
.First1::-webkit-scrollbar-thumb,
.second1::-webkit-scrollbar-thumb {
  background-color: #8ccfe0; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Border radius of the thumb */
}

.First1::-webkit-scrollbar-thumb:hover,
.second1::-webkit-scrollbar-thumb:hover {
  background-color: #264bc7; /* Color of the thumb on hover */
}

.First1::-webkit-scrollbar-track,
.second1::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the scrollbar track */
}

.borderbottom {
  border-bottom: 2px solid rgb(171, 188, 199);
}
.vertical-line {
  border-left: 1px solid rgb(128, 123, 123); /* Adjust width and color as needed */
  height: 400px;
  margin-top: 30px;
}

.heading {
  color: black;
}
.fileurlinput {
  display: grid;
  grid-template-columns: 3fr 1fr;
}
.urlbox {
  width: 100%;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.select {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.submitbtn {
  width: 50%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10%;
  margin-left: 25%;
}
/* .outer1 {
  width: 85%;
  margin-left: 3%;
  display: grid;
  grid-template-columns: 48fr 1fr 48fr;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  margin-top: 50px;
} */
.color {
  color: #198754;
}

.navbar-nav .nav-link:hover {
  color: white;
}

.Notes {
  width: 600px;
  overflow-x: auto;
  white-space: nowrap;
}

.Note {
  display: inline-block;
  vertical-align: top;
  width: 90%;
  margin-left: 30px;
}

.Notes::-webkit-scrollbar {
  height: 4px;
}

.deleteNote {
  margin-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: 10fr 1fr;
}
.head {
  display: grid;
  grid-template-columns: 7fr 1fr;
}
.options {
  margin-top: 15px;
  width: 100%;
  height: 35vh;
  overflow: auto;
}
.questions::-webkit-scrollbar,
.options::-webkit-scrollbar {
  width: 3px;
}
.question {
  width: 95%;
  padding-left: 10px;
  margin-top: 3px;
  display: grid;
  grid-template-columns: 48fr 1fr 1fr;
  gap: 10px;
  color: #264bc7;
}
.iconedit {
  padding-top: 3px;
  color: #264bc7;
}
.icontrash {
  padding-top: 3px;
  color: #264bc7;
}
.icontrash:hover {
  color: red;
}
.questions {
  width: 100%;
  padding-left: 3px;
  height: 400px;
  overflow: auto;
}
.Notes::-webkit-scrollbar-thumb,
.questions::-webkit-scrollbar-thumb,
.options::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Border radius of the thumb */
}
.Notes::-webkit-scrollbar-thumb:hover,
.questions::-webkit-scrollbar-thumb:hover,
.options::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the thumb on hover */
}
.Notes::-webkit-scrollbar-track,
.questions::-webkit-scrollbar-track,
.options::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the scrollbar track */
}
.option {
  margin-left: 2px;
  width:100%;
  margin-top: 10px;
 
  border-radius: 0.35rem;
}
.form-control:hover,
.option:hover {
  color: #6e707e;
  background-color: #fff;
  border-color: #bac8f3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
}

.optiontextbox {
  height: 100%;
  border: 1px solid #d1d3e2;
  outline: none;
  border-radius: 0.35rem;
  padding: 0.375rem 0.75rem;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  color: #6e707e;
}


.buttondiv {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.counts{
  width: 800px;
  height: 200px;
  margin: auto;
  display:grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-content:space-evenly;
  border-radius: 10px;
  background-color: #B3B6E1;
  box-shadow: 
  10px 10px 0px rgba(175, 213, 255, 0.8),
  20px 20px 0px rgba(198, 217, 238, 0.8);
}
.countchild{
 margin: auto;
  background-color: white;
  width: 150px;
  height: 150px;
  border-radius: 10px;
 padding: 10px;
  font-weight: bold;
  text-align: center;
  overflow:hidden ;
}

.circleimg{
  width: 70px;
  height: 60px;
  margin:auto;
  text-align: center;
  background-color: #D9D9D9;
}

.Activeuser{
  background-color: #ECFDF3;
  color: #037847;
  font-size: small;
  width: 90px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
}

.InActiveuser{
  background-color: #EDEFF1;
  color: #6C778B;
  font-size: small;
  width: 100px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
}



@media screen and (max-width: 480px) {
  .anchorlike{
    font-size: 1rem
  }
 .twosearch{
  grid-template-columns: 1fr ;
 }
  .counts{
 margin: auto;
 width: 300px;
   grid-template-columns: 1fr 1fr;
   gap: 10px;
  height:auto;
  padding: 10px;
   
  }
  
  .edititems b{
    font-size: 0.9rem;
  }
  .outer{
    grid-template-columns: 1fr;
  
  }
  .countchild{
    width: 125px;
    height: 170x;
  }
  .dash{
    display: flex;
    flex-direction: column;
  }
  .table-container{
    width: 88vw;
  }
}